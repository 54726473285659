@import "../../../styles/helpers";

.donutLabels {
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    display: flex;
    align-items: center;
    margin-top: 13px;
  }
}

