.place-order {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    background-color: #ffd000;
    position: relative;
    overflow: hidden;
    border: none;
    cursor: pointer;
    
    .text {
      width: 100%;
      height: 100%;
      line-height: 60px;
      opacity: 0;
      color: rgb(2, 0, 0);
      font-weight: bold;
      font-size: 16px;
      position: absolute;
      top: 0;
      transition: all .2s;
      transform: translateY(20px);
    }
    
    &:focus {
      outline: none;
    }
    
    .forklift {
      display: none;
      transform: scale(.4);
      position: absolute;
      top: -2px;
      left: -65px;
      animation: 4s lift-moving infinite;
  
      .upper {
        width: 34px;
        height: 20px;
        margin-bottom: 2px; 
        border: 5px solid #ff7745;
        position: relative;
        border-radius: 10px 50px 10px 5px;
  
        &:after {
          content: '';
          width: 30px;
          height: 50px;
          position: absolute;
          left: 170%;
          top: 12px;
          box-sizing: border-box;
          border-left: 5px solid #000;
          border-bottom: 6px solid #000;
          border-radius: 0 0 0 5px;
          transform-origin: center;
        }
      }
  
      .lower {
        width: 60px;
        height: 30px;
        position: relative;
        background-color: #FF4500;
        border-radius: 5px 15px 10px 10px;
  
        &:before, &:after {
          content: '';
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0;
          bottom: -10px;
          border: 3px solid #2b59b5;
          border-radius: 50%;
          background-color: #000;
        }
  
        &:after {
          left: 36px;
        }
      }
    }
  
    .box {
      display: none;
      width: 50px;
      height: 50px;
      background-color: #1d1003;
      border-radius: 5px;
      position: absolute;
      top: 56px; // 6px
      left: 50px;
      transform: scale(.4);
      animation: 4s box infinite;
  
      &:before, &:after {
        content: '';
        width: 4px;
        height: 12px;
        left: 21px;
        background-color: #ffbf59;
        position: absolute;
      }
  
      &:after {
        height: 10px;
        left: 25px;
      }
    }
    
    &--default {
      .default {
        opacity: 1;
        transform: translateY(0);
      }
      
      .done {
        transform: translateY(20px);
      }
    }
    
    &--placing {
      .forklift {
        display: block;
      }
      
      .box {
        display: block;
      }
      
      .default {
        transform: translateY(-20px);
      }
    }
    
    &--done {
      .done {
        opacity: 1;
        transform: translateY(0);
      }
      
      .default {
        transform: translateY(-20px);
      }
    }
  }
  
  
  
  @keyframes lift-moving {
    0% {
      left: -65px;
    }
    
    50% {
      left: 20px;
    }
    
    60% {
      left: 15px;
    }
    
    100% {
      left: 400px;
    }
  }
  
  @keyframes box {
    0% {
      top: 56px;
    }
    
    20% {
      top: 6px;
      left: 50px;
    }
    
    50% {
      top: 6px;
      left: 50px;
    }
    
    60% {
      top: 6px;
      left: 45px;
    }
    
    100% {
      top: 6px;
      left: 430px;
    }
  }