html {
  font-family: $font-family-base;
  font-weight: 100;
  font-size: $font-size-initial;
}

body {
  overflow-x: hidden;
  color: $text-color;
  background: $body-bg;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

html,
body,
#app {
  height: 100%;
}

p {
  margin-bottom: 0;
}

a,
.dropdown-item,
.btn {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  font-family: $font-family-base;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

th {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
}

td {
  color: $default;
}

.table td {
  vertical-align: middle;
}

ul ul,
ol ul {
  list-style: none;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}
