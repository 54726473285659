@import "../../../styles/helpers";

.iconList {
  div {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 24px;

    span {
      margin-left: 8px;
      font-size: $font-size-sm;
      font-weight: $font-weight-normal;
      color: $base-dark-gray;
    }
  }
}

