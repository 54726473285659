@import "../../styles/helpers";

.tableTitle {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px;

  img {
    margin-left: 16px;
  }
}

.transactionTitleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 24px 13px 16px;
}

.widgetContentBlock {
  margin: 0 24px 24px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 13px 24px 13px 16px;
  border-radius: 8px;
  background-color: $base-light-gray;
}

.image {
    // width: 32px;
    // height: 32px;
    width: 45px;
    height: 45px;
    border: 2px solid #43bc13;
    padding: 1px;
}

.imageBanner {
  width: 150px;
  height: 75px;
}

.checkboxCol {
  width: 5%;
}

.nameCol {
  width: 15%;
}


.marker {
  width:0;
  height:0;
}

.marker  span {
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  width: 30px;
  height: 30px;
  color:#fff;
  background: #4D2D73;
  border:solid 2px;
  border-radius: 0 70% 70%;
  box-shadow:0 0 2px #000;
  cursor: pointer;
  transform-origin:0 0;
  transform: rotateZ(-135deg);
}

.marker b {
  transform: rotateZ(135deg)
}

.temporary-marker span {
  background: #D3D3D3;
}